import { routingTable } from "../routes";

const difficultWordsFilterFromLocation = (location) => {
  return {
    submitted: location.query.submitted,
    subCategory: location.query.subCategory,
    isAllowed: location.query.isAllowed,
    numSubmitted: location.query.numSubmitted,
    name: location.query.name,
    submittedBy: location.query.submittedBy,
    approvedByOrg: location.query.approvedByOrg
  };
};

const correctnessWordsFilterFromLocation = (location, subCategory) => {
  let actualSubCategory = subCategory;
  if(!subCategory) {
    if(location.pathname === routingTable.organizationNames) {
      actualSubCategory = "ORGANIZATION_NAMES";
    }else{
      const correctnessCategoryIds = categoryIdsFromDataDiv();
      if(correctnessCategoryIds.includes("SPELLING_NEW")) {
        actualSubCategory = "SPELLING_GIGANTMOLEX";
      }else{
        actualSubCategory = "SPELLING_OPENTAAL";
      }
    }
  }
  return {
    mainCategory: "correctness",
    submitted: location.query.submitted,
    isAllowed: location.query.isAllowed,
    isActive: location.query.isActive,
    name: location.query.name,
    subCategory: actualSubCategory
  };
};

const categoryIdsFromDataDiv = () => {
  const mainDiv = document.getElementById("main");
  const categories = JSON.parse(mainDiv.dataset.categories);
  const correctness = categories.correctness;

  if(correctness && correctness.length) {
    return correctness.map((category) => category.id);
  }
};

class ViewStore {
  wordsStore;
  wordStore;
  categoriesStore;

  constructor(wordsStore, wordStore, categoriesStore) {
    this.wordsStore = wordsStore;
    this.wordStore = wordStore;
    this.categoriesStore = categoriesStore;
  }

  parseCategoryIds() {
    if(this.correctnessCategoryIds) {
      return;
    }

    this.correctnessCategoryIds = categoryIdsFromDataDiv();
  }

  navigatedTo(routeName, nextState) {
    switch(routeName) {
      case "difficultWords" : {
        this.loadDifficultWords(nextState);
        break;
      }
      case "showDifficultWord" : {
        this.loadDifficultWord(nextState);
        break;
      }
      case "editDifficultWord" : {
        this.loadDifficultWord(nextState);
        break;
      }
      case "newDifficultWord" : {
        this.newDifficultWord();
        break;
      }
      case "correctnessWords": {
        this.loadCorrectnessWords(nextState);
        break;
      }
      case "showCorrectnessWord": {
        this.loadCorrectnessWord(nextState);
        break;
      }
      case "newCorrectnessWord": {
        this.newCorrectnessWord();
        break;
      }
      case "editCorrectnessWord": {
        this.loadCorrectnessWord(nextState);
        break;
      }
      case "organizationNames": {
        this.loadCorrectnessWords(nextState, "ORGANIZATION_NAMES", null);
        break;
      }
      case "showOrganizationName": {
        this.loadCorrectnessWord(nextState);
        break;
      }
      case "newOrganizationName": {
        this.newCorrectnessWord("ORGANIZATION_NAMES");
        break;
      }
      case "editOrganizationName": {
        this.loadCorrectnessWord(nextState);
        break;
      }
    }
  }

  loadDifficultWords(nextState) {
    const page = nextState.location.query.page;
    const start = nextState.location.query.start || "-1";
    const filter = difficultWordsFilterFromLocation(nextState.location);
    this.wordsStore.refresh({page, start}, filter);
    this.categoriesStore.refresh();
  }

  loadDifficultWord(nextState) {
    const wordId = nextState.params.wordId;
    this.wordStore.loadWord(wordId);
    this.categoriesStore.refresh();
  }

  newDifficultWord() {
    this.categoriesStore.refresh();
    const subCategoryPromise = this.categoriesStore.categories.promise().then(({categories}) =>
      categories[0].id
    );

    this.wordStore.newWord(subCategoryPromise);
  }

  loadCorrectnessWords(nextState, subCategory = null, startDefault = "-1") {
    this.parseCategoryIds();
    let actualSubCategory = subCategory || "SPELLING_OPENTAAL";
    if(subCategory == null && this.correctnessCategoryIds && this.correctnessCategoryIds.includes("SPELLING_NEW")) {
      actualSubCategory = "SPELLING_GIGANTMOLEX";
    }

    const page = nextState.location.query.page || "1";
    const start = nextState.location.query.start || startDefault;
    const filter = correctnessWordsFilterFromLocation(nextState.location, actualSubCategory);

    this.wordsStore.refresh({page, start}, filter);
    this.categoriesStore.refresh(filter);
  }

  loadCorrectnessWord(nextState) {
    const wordId = nextState.params.wordId;
    const filter = {
      mainCategory: "correctness"
    };

    this.wordStore.loadWord(wordId);
    this.categoriesStore.refresh(filter);
  }

  newCorrectnessWord(subCategory = null) {
    this.parseCategoryIds();
    let actualSubCategory = subCategory || "SPELLING_OPENTAAL";
    if(subCategory == null && this.correctnessCategoryIds && this.correctnessCategoryIds.includes("SPELLING_NEW")) {
      actualSubCategory = "SPELLING_GIGANTMOLEX";
    }
    const mainCategory = "correctness";
    const filter = {
      mainCategory: mainCategory
    };

    this.categoriesStore.refresh(filter);
    const subCategoryPromise = this.categoriesStore.categories.promise().then(({categories}) =>
      categories.find((category) => category.id === actualSubCategory).id
    );

    this.wordStore.newWord(subCategoryPromise, mainCategory);
  }
}

export default ViewStore;
export { correctnessWordsFilterFromLocation, difficultWordsFilterFromLocation };

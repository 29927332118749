import React from "react";
import I18n from "i18n-js";

import NavigationSidebar from "../components/NavigationSidebar.js";

import routes from "../routes.js";

const NavigationSidebarContainer = ({categories: {readability, correctness}}) => {
  const navigationItems = [];
  if(readability && readability.length) {
    navigationItems.push({url: routes.get("difficultWords"), label: I18n.t("navigation.difficult_words"), iconClass: "fa-list"});
  }

  if(correctness && correctness.length) {
    const correctnessCategoryIds = correctness.map((category) => category.id);

    const correctnessNavigationItems = [];
    if(correctnessCategoryIds.includes("SPELLING_OPENTAAL") || correctnessCategoryIds.includes("SPELLING_NEW")) {
      correctnessNavigationItems.push({url: routes.get("correctnessWords"), label: I18n.t("navigation.correctness_words")});
    }
    if(correctnessCategoryIds.includes("ORGANIZATION_NAMES")) {
      correctnessNavigationItems.push({url: routes.get("organizationNames"), label: I18n.t("navigation.organization_names")});
    }
    navigationItems.push({label: I18n.t("navigation.correctness"), iconClass: "fa-list", subItems: correctnessNavigationItems});
  }

  return (
    <NavigationSidebar items={navigationItems} />
  );
};

export default NavigationSidebarContainer;
